@import '../../styles/variables.module.scss';

.discord-cta {
  margin: 0 auto;
  width: 132em;
  border-radius: 2.4em;
  background: #161C31;

  padding: 6em 0 8em 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 34em;

    padding: 3.2em 3.8em 4.5em 3.8em;
  }
}

.discord-cta__title {
  width: 82em;
  
  h1 {
    font-size: 10em;
    text-align: center;
    line-height: 120%;
  }

  @media (max-width: 768px) {
    width: 28em;

    h1 {
      font-size: 5.2em;
    }
  }
}

.discord-cta__desc {
  margin-top: 4em;

  p {
    text-align: center;
    color: rgba(255, 255, 255, 0.70);
    font-size: 2em;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    margin-top: 3em;

    p {
      font-size: 1.6em;
    }
  }
}

.discord-cta__button {
  padding: 1.5em 2.8em;
  border-radius: 10em;
  background: #5865F2;
 
  display: flex;
  justify-content: center;
  align-items: center;


  gap: 1em;

  margin-top: 5em;

  @media (max-width: 768px) {
    margin-top: 4em;
  }
  
  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  svg {
    width: 2.6em;
    height: 2.6em;
  }
}