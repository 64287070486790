.dashboard-section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 1em;
  }
}

.dashboard-section__header-left {
  display: flex;
  align-items: center;
  gap: 1.5em;

  @media (max-width: 768px) {
    gap: 0.5em;
    width: 100%;
    justify-content: space-between;
  }
}

.dashboard-section__title {
  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.4em;
    }
  }
}

.dashboard-section__header-right {
  opacity: 0.5;
  display: flex;
  align-items: center;
  gap: 1em;
 
  h1 {
    font-size: 2.3em;

  }

  svg {
    width: 3em;
    margin-top: 0.1em;
    height: 3em;
  }
}

.dashboard-section__pills {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 1em;

  @media (max-width: 768px) {
    gap: 0.4em;
  }
}

.dashboard-section__timeframe-pill, .dashboard-section__chains-pill {
  border-radius: 10em;
  background: #20263B;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.8em;

  padding: 0 1.4em;
  height: 3.2em;

  p {
    font-size: 1.4em;
    font-weight: 500;
  }

  img {
    width: 1.6em;
    height: 1.6em;
    filter: brightness(70%) invert(1) grayscale(100%);
  }

  @media (max-width: 768px) {
    padding: 0 0.8em;
    height: 2.5em;
    gap: 0.5em;

    p {
      font-size: 1em;
      font-weight: 500;
    }
  
    img {
      width: 1.2em;
      height: 1.2em;
    }
  }
}