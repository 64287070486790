.live-section {

}

.live-section__header {
  display: flex;
  align-items: center;
  gap: 1.75em;
  margin-bottom: 3em;

  @media (max-width: 768px) {
    margin-bottom: 2em;
  }
}

.live-section__title {
  h1 {
    font-size: 5.2em;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4em;
    }
  }
}

.live-section__live-circle {
  margin-top: 0.4em;
  width: 1.4em;
  height: 1.4em;
  background-color: #F1271D;
  border-radius: 100%;
}

.live-section__content {
  height: 42em;
  width: 100%;
  position: relative;

  .simplebar-placeholder::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 20em;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(to left, #111525, transparent);
    z-index: 1;

    @media (max-width: 768px) {
      width: 10em;
    }
  }
}

.live-section__cards {
  display: flex;
  gap: 1.6em;
}

.live-card {
  min-width: 21.6em;
  height: 37.4em;

  padding: 2.4em 0;

  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;
}

.live-card__pfp {
  position: relative;

  height: 13.2em;
  width: 13.2em;

  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.live-card__badge {
  position: absolute;

  top: 0;
  right: 0;

  width: 2.7em;
  height: 3.4em;

  svg {
    width: 2.7em;
    height: 3.4em;
  }
}

.live-card__viewers {
  height: 4em;
  padding: 0 1.2em;
  border-radius: 10em;
  background: #1C2339;

  margin: 1.2em auto 0 auto;
  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 0.6em;

  &__icon {
    background-color: #F1271D;
    width: 1.2em;
    height: 1.2em;
    border-radius: 100%;
    margin-right: 0.3em;
  }

  &__text {
    font-size: 1.4em;
    font-weight: 500;
  }

  &__link {
    margin-top: 0.1em;
    width: 1.7em;
    height: 1.7em;
  }
}

.live-card__name {
  margin: 1.75em auto 0 auto;

  p {
    font-size: 2em;
    font-weight: 500;
    text-align: center;
  }
}

.live-card__casino {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  margin-top: 1em;

  img {
    width: 2.4em;
    height: 2.4em;
    border-radius: 100%;
  }

  p {
    font-size: 1.4em;
    font-weight: 500;
  }
}

.live-card__view-profile {
  width: 17.6em;
  height: 4em;
  background: #2D395C;
  border-radius: 10em;
  margin: 2.25em auto 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 1.4em;
    font-weight: 500;
  }
}