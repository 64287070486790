.score-calculator {
  width: 132.2em;
  height: 75em;
  max-height: 90vh;
  border-radius: 3.2em;

  background-color: #161C31;

  padding: 3.2em 2.6em 3em 3.2em;

  display: flex;
  gap: 7em;

  position: relative;
  

  @media (max-width: 768px) {
    padding: 2.2em 2em 2em 2em;
    height: 95vh;
    max-height: 95vh;
    
    width: 39em;

    flex-direction: column;

    overflow-y: auto;
  }
}