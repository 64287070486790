.creator-faq {
  margin: 0 auto;
  margin-bottom: 10em;
}

.creator-faq__title {
  h1 {
    font-size: 5.2em;
    text-align: center;
  }

  margin-bottom: 3.5em;

  @media (max-width: 768px) {
    h1 {
      font-size: 4.5em;
      line-height: 125%;
    }
  }
}

.creator-faq__list {
  margin: 0 auto;

  border-radius: 2.4em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  width: 84em;

  padding: 3em 3.3em;

  @media (max-width: 768px) {
    width: 34em;
    padding: 2em 1.5em 2.5em 1.5em;
  }
}

.creator-faq__seperator {
  margin: 3em 0;
  background-color: #2D395C;
  height: 0.1em;
  width: 100%;

  @media (max-width: 768px) {
    margin: 2em 0;
  }
}

.creator-faq__question {

}

.creator-faq__question__header {
  display: flex;
  height: 3.2em;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  cursor: pointer;
}

.creator-faq__question__title {
  p {
    font-size: 2.2em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.6em;
    }
  }
}

.creator-faq__toggle {
  min-width: 3.2em;
  min-height: 3.2em;
}

.creator-faq__question__content {
  margin-top: 3em;

  p {
    font-size: 1.6em;
    color: rgba(255, 255, 255, 0.70);
  }

  @media (max-width: 768px) {
    margin-top: 2.5em;

    p {
      font-size: 1.3em;
    }
  }
}

.creator-faq__question--verified__tiers {
  display: flex;
  flex-direction: column;
  gap: 2em;

  margin-top: 3em;
}

.creator-faq__question--verified__tier {
  display: flex;
  align-items: center;
  gap: 3em;

  @media (max-width: 768px) {
    gap: 2em;
  }
}

.creator-faq__question--verified__level {
  display: flex;
  align-items: center;
  gap: 1em;

  p {
    font-size: 2em;
    font-weight: 400;
    color: #fff;
  }

  svg {
    min-height: 2.9em;
    min-width: 2.3em;
    height: 2.9em;
    width: 2.3em;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.6em;
    }
  }
}