@import './variables.module.scss';
@import url("https://use.typekit.net/hty3leq.css");
@import url("https://use.typekit.net/rnk6wip.css");

html {
  background-color: #111525;
}

body {
  font-family: "transducer", sans-serif;
  font-weight: 400;
  font-size: clamp(0px, 0.6614vw, 10px);
  color: #fff;

  @media (max-width: 768px) {
      font-size: clamp(0px, 2.564vw, 100px);
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-content {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-family: "transducer-condensed", sans-serif;
  font-weight: 550;
}

html::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
html {
  scrollbar-width: none;
}

/* For IE and Edge */
html {
  -ms-overflow-style: none;
}

/* Firefox global scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.20) #20263B;
}

/* WebKit browsers global scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #20263B;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
  visibility: hidden;

  -webkit-box-shadow: inset 0 0 6px rgba(247, 73, 73, 0.07);
  background-color: red;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.20);;
  border-radius: 20px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.30);
}

.button {
  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  &:hover.disabled {
    cursor: default;

  }

  &.disabled {
    filter: brightness(0.8);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;                 /* enable flexbox */
  align-items: center;           /* vertical centering */
  justify-content: center;       /* horizontal centering */
  // visibility: hidden;            /* hidden by default */
  // opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  z-index: 999;
}

.desktop {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}