

.main-chart__casino-keys {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  column-gap: 2.4em;
  row-gap: 1em;

  margin-bottom: 3em;

  @media (max-width: 768px) {
    margin-bottom: 1.5em;
    column-gap: 1.4em;
    row-gap: 0.5em;
  }
}

.main-chart__casino-key {
  display: flex;
  align-items: center;
  gap: 1em;

  @media (max-width: 768px) {
    gap: 0.5em;
  }
}

.main-chart__casino-pill {
  width: 1.2em;
  height: 1.2em;
  border-radius: 100%;

  @media (max-width: 768px) {
    width: 0.8em;
    height: 0.8em;
  }
}

.main-chart__casino-name {
  p {
    font-size: 1.7em;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2em;
      font-weight: 400;
    }
  }
}

.main-chart__chart-options-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-chart__chart-options {
  margin-top: 3em;
  margin-bottom: 1em;

  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #171B2A;
  padding: 0.5em 0.6em;

  width: fit-content;
  display: flex;

  @media (max-width: 768px) {
    margin-top: 1.5em;
    margin-bottom: 0em;

    background-color: transparent;
    border: none;
    flex-direction: column;
    border-radius: 2em;
    gap: 1em;
  }
}

.main-chart__chart-option {
  border-radius: 25em;
  padding: 0.6em 3em 0.8em 3em;

  display: flex;
  justify-content: center;
  align-items: center;
  

  background-color: #171B2A;

  p {
    font-size: 1.8em;
    font-weight: 400;
  }

  &.active {
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background: #2A3045;
  }

  @media (max-width: 768px) {
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background-color: #171B2A;

    p {
      font-size: 1.4em;
      font-weight: 500;
    }
  }
}

.main-chart-container__victory-chart {
  
}