.dashboard {
  margin: 0 auto;
  width: 132em;

  display: flex;
  flex-direction: column;
  gap: 2.5em;

  margin-top: 4em;

  @media (max-width: 768px) {
    gap: 1.5em;
    width: 34em;
  }
}

.dashboard-section {
  padding: 2.4em 3.2em;

  border-radius: 1.2em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  @media (max-width: 768px) {
    padding: 0.8em 1.4em;
    width: 34em;
  }
}