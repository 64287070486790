@import '../../../../styles/variables.module.scss';

.consultancy {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 12em 9em 12em 9em;

  position: relative;
  gap: 15em;

  @media (max-width: 768px) {
    gap: 2em;
    flex-direction: column-reverse;
    padding: 5em 2.5em 0 2.5em;
  }
}

.consultancy__title {
  margin-top: -2em;
  margin-bottom: 2em;

  h1 {
    font-family: "transducer-condensed", sans-serif;
    font-size: 10em;
    font-weight: 550;
    line-height: 110%;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 5.2em;
      line-height: 120%;
    } 
  }
}

.consultancy__desc {
  opacity: 0.7;
  margin: 4.5em 0 5.5em 0;
  max-width: 56em;

  p {
    font-size: 2em;
    font-weight: 400;
    line-height: 180%;
  }

  @media (max-width: 768px) {
    max-width: 31em;
    margin: 2.5em 0 3em 0;

    p {
      font-size: 1.6em;
      font-weight: 400;
    }
  }
}

.consultancy__left {
  margin-top: 2em;
  width: 40em;

  @media (max-width: 768px) {
    width: 100%;

    display: flex;
    justify-content: center;
  }
}

.consultancy__right {
  margin-top: 2em;
  width: 60em;

  @media (max-width: 768px) {
    width: 34em;
  }
}

.live-deposits {
  width: 100%;
  padding: 2.8em 3.3em 3.9em 3.3em;

  border-radius: 2.4em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #222635;

  display: flex;
  flex-direction: column;

  
  @media (max-width: 768px) {
    width: 34em;
    margin: 2.5em 0 3em 0;

    p {
      font-size: 1.6em;
      font-weight: 400;
    }
  }
}

.live-deposits__title {
  margin-bottom: 2.7em;

  p {
    font-size: 2em;
  }
}

.live-deposits__networks {
  display: flex;
  flex-direction: column;
  gap: 1em;

  margin-bottom: 2.9em;
}

.live-deposits__tracker {
  border-radius: 1.28em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: rgba(20, 24, 40, 0.5);

  padding: 1.3em 2em;

  display: flex;
  align-items: center;
  gap: 1em;

  img {
    width: 2em;
    height: 2em;
  }

  p {
    color: #fff;
    font-size: 1.6em;
    font-weight: 300;
  }
}

.live-deposits__sub-title {
  margin-bottom: 1em;

  p {
    font-size: 1.5em;
  }
}

.live-deposits__desc {
  margin-top: 2.6em;

  p {
    font-size: 1.2em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.30);
    line-height: 200%;
  }
}

.consultancy__contact {
  margin-top: 4.3em;
  height: 5.6em;
  width: 15.8em;
  background: $pink-gradient;
  border-radius: 10.4em;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin-top: 2.5em;

    height: 4.8em;
    width: 14.6em;

    p {
      font-size: 1.5em;
    }
  }
}