.creator-deal {
  background: #1E2741;
  border-radius: 1.6em;
}

.creator-deal__header {
  height: 5.9em;
  padding: 0 2.6em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0 2em;
  }
}

.creator-deal__header__left {
  display: flex;
  align-items: center;
  gap: 1.6em;

  @media (max-width: 768px) {
    gap: 1.2em;
  }
}

.creator-deal__header__casino {
  p {
    font-size: 2em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.7em;
    }
  }
}

.creator-deal__header__badge {
  width: 2.3em;
  height: 2.9em;
}

.creator-deal__header__date {
  margin-left: 1.5em;

  p {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
    font-size: 1.7em;
  }
}

.creator-deal__header__arrow {
  width: 1.6em;
  height: 1.6em;
  min-width: 1.6em;
  min-height: 1.6em;

  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    transform: rotate(180deg);
  }
}

.creator-deal__site-deal {
  border-top: 0.1em solid #2D395C;
  padding: 2.6em 2.6em 3.5em 2.6em;

  display: flex;
  flex-direction: column;
  gap: 2em;

  @media (max-width: 768px) {
    padding: 2em 2em 2.5em 2em;
  }
}

.creator-deal__site-deal__site {
  display: flex;
  align-items: center;
  gap: 1em;

  img {
    width: 2em;
    height: 2em;
  }

  p {
    font-size: 1.8em;
    font-weight: 500;
  }
}

.creator-deal__site-deal__content {
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.creator-deal__details {
  display: flex;
  flex-direction: column;
  gap: 2.8em;
}

.creator-deal__details__title {
  p {
    font-size: 2em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.8em;
    }
  }
}

.creator-deal__details__list {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6em;

  @media (max-width: 768px) {
    overflow: scroll;
    flex-wrap: nowrap;
  }
}


.creator-deal__detail {
  flex-shrink: 0;
  height: 12em;
  width: fit-content;
  padding: 0 3.2em;
  border-radius: 1.6em;
  background: #161C31;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 1.6em;

  img {
    height: 2em;
  }
  
  p {
    font-size: 1.6em;
    font-weight: 500;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: fit-content;
    height: 10em;
    padding: 0 2em;

    img {
      height: 1.8em;
    }
    
    p {
      font-size: 1.4em;
    }
  }
}