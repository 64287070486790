@import "../../styles/variables.module.scss";

.contact-section {
  width: 132em;
  margin: 7em auto 10em auto;

  gap: 7.7em;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;

    width: 34em;
    margin-top: 4.5em;
    margin-bottom: 6em;
    gap: 4.7em;
  }
}

.contact__left {
  width: 76em;
  height: 65em;
  padding: 3em 3.4em 4.3em 4em;
  border-radius: 2.4em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  @media (max-width: 768px) {
    height: unset;
    padding: 2em 2em 3.5em 2.3em;
    width: 100%;
  }
}

.contact__fields {
  display: flex;
  flex-direction: column;
  gap: 2.6em;
}

.contact__field {
  display: flex;
  flex-direction: column;
  gap: 0.8em;

  label {
    font-size: 1.7em;
    font-weight: 500;
  }

  input, textarea {
    height: calc(5.6em / 1.7);
    font-size: 1.7em;
    padding: calc(1.5em / 1.7) calc(2.5em / 1.7);
    background: #171B2A;
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    border-radius: calc(2.4em / 1.7);
    color: #fff;
    margin-left: -0.1em;

    @media (max-width: 768px) {
      width: 100%;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.20); 
      font-weight: 300;
    }

    &:active, &:focus, &:focus-visible, &:focus-within {
      border: 0.15em solid #FD9EFB;
      outline: none;
      box-shadow: none;
    }

    &.error {
      border: 0.15em solid #CC3055;
    }
  }

  textarea {
    height: calc(22em / 1.7);
    resize: none;
  }
}

.contact-form__footer {
  margin-top: 3.2em;
  display: flex;
  gap: 3em;
  align-items: center;

  p {
    font-size: 2em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    gap: 2em;

    flex-direction: column;
    align-items: start;

    p {
      font-size: 1.6em;
      font-weight: 500;
    }
  }
}

.contact__success {
  p {
    color: $pink-color;
  }
}

.contact__error {
  p {
    color: #CC3055;
  }
}

.contact-form__submit {
  height: 5.6em;
  padding: 0 3.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $pink-gradient;
  border: none;
  color: #fff;
  border-radius: 10em;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }
}




.contact__right {
  display: flex;
  flex-direction: column;
  gap: 4.8em;
}

.contact__info-piece {
  display: flex;
  flex-direction: column;
  gap: 3em;
}

.contact__info-piece__title {
  p {
    font-size: 1.7em;
    font-weight: 500;
  }
}

.contact__info-piece__content {
  display: flex;
  gap: 2em;

  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 1.8em;
    font-weight: 300;
  }
}

.contact__icon {
  width: 8em;
  height: 8em;
  border-radius: 100%;
  
  background: #171B2A;
  border: 0.1em solid rgba(255, 255, 255, 0.10);

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 2.6em;
    height: 2.6em;
  }
}