@import '../../../../styles/variables.module.scss';

.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding-bottom: 4em;
  overflow: hidden;

  @media (max-width: 768px) {
    margin-top: 4em;
  }
}

.testimonials__introduction {
  width: 70em;
  text-align: center;

  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 2em;
    font-weight: 400;
    line-height: 185%;
  }

  @media (max-width: 768px) {
    width: 34em;

    p {
      font-size: 1.6em;
      line-height: 200%;
    }
  }
}

.testimonials__title {
  width: 70em;
  text-align: center;
  margin-top: 1em;

  h1 {
    font-size: 7.6em;
    font-weight: 550;
    line-height: 185%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 3em;
    margin-bottom: 3.5em;

    h1 {
      font-size: 5.2em;
      font-weight: 550;
      line-height: 120%;
    }
  }
}

.testimonials-buttons {
  width: 70em;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5em;

  margin-bottom: 5em;

  @media (max-width: 768px) {
    width: 34em;

    margin-bottom: 6em;
  }
}

.testimonial-button {
  width: 7em;
  height: 7em;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  &.selected {
    border: 0.2em solid #fff;

    img {
      filter: grayscale(0%);
    }
  }

  &.disabled {
    filter: none;
  }

  img {
    width: 5.2em;
    height: 5.2em;
    border-radius: 100%;

    filter: grayscale(100%);
  }

  .testimonial-button__overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.25);
    width: 5.2em;
    height: 5.2em;
    border-radius: 100%;
  }
}

.testimonial__controls {
  margin-top: 2em;
  display: flex;
  gap: 1em;
}

.testimonial__spin {
  padding: 0.8em 2.3em;

  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  display: flex;
  align-items: center;
  justify-content: center;


  gap: 0.7em;

  .testimonial__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2em;
    height: 2em;
  }

  p {
    font-size: 1.6em;
  }

  @media (max-width: 768px) {
    padding: 0.6em 1.5em;
    gap: 0.5em;

    width: 8.5em;
    height: 3.2em;

    svg {
      width: 1.6em;
      height: 1.6em;
    }

    p {
      font-size: 1.2em;
    }
  }
}

.testimonial-game {
  width: 152em;

  position: relative;

  @media (max-width: 768px) {
    width: 100em;
  }
}

.testimonial-game::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10em; /* Adjust width for fade effect */
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to right, #111525, transparent); /* Change #fff to match your container's background */
  z-index: 1;
}

/* Right fade */
.testimonial-game::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10em;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(to left, #111525, transparent);
  z-index: 1;
}

.testimonial-game__ticker, .testimonial-game__ticker-ball {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -2.7em;
  width: 0.4em;
  height: 4.5em;
  border-radius: 0.3em;
  background-color: #fff;
  z-index: 1;
}

.testimonial-game__ticker-ball {
  width: 1.6em;
  height: 1.6em;
  border-radius: 100%;
}

.testimonial__logo {
  position: absolute;
  width: 19%;

  z-index: -1;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  
  @media (max-width: 768px) {
    top: 75%;
  }
}

.testimonial-cards-wrapper {
  width: 100%;     // Or however wide you want the viewport
  height: 36em;
  overflow-x: hidden;
  overflow-y: visible;
}

.testimonial-cards {
  margin-top: 1em;
  display: flex;
  gap: 1%;
}

.testimonial-card {
  min-width: 33%;
  height: 34em;
  

  padding: 3em 3.4em;
  
  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: rgba(22, 28, 49, 0.75);
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 0.6;
  @media (max-width: 768px) {
    padding: 2.2em 3em;
  }
}

@supports ((-webkit-backdrop-filter: blur(17.3px)) or (backdrop-filter: blur(17.3px))) {
  .testimonial-card {
    background: rgba(22, 28, 49, 0.05);
    // -webkit-backdrop-filter: blur(17.3px);
    // backdrop-filter: blur(17.3px);
  }
}

.testimonial-card.selected {
  opacity: 1;
  border: 2px solid #FD9EFB;
  transform: scale(1.02);
}

.testimonial-card__message {

  width: 100%;

  p {
    text-wrap: wrap;

    font-size: 1.7em;
    font-weight: 300;
    line-height: 190%;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.4em;
      font-weight: 300;
      line-height: 190%;
    }
  }
}

.testimonial-card__footer {
  display: flex;
  align-items: center;
  gap: 1.7em;

  img {
    height: 5.2em;
    width: 5.2em;
    border-radius: 100%;
  }

  @media (max-width: 768px) {
    img {
      height: 4em;
      width: 4em;
      min-height: 4em;
      min-width: 4em;
    }
  }
}

.testimonial-card__person {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.testimonial-card__person__name {
  p {
    margin-top: -0.3em;
    font-size: 2em;
    font-weight: 550;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.7em;
    }
  }
}

.testimonial-card__person__role {
  p {
    font-size: 1.5em;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2em;
    }
  }
}