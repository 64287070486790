.footer {
  background-color: #161C31;
}

.footer__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6.4em;
  padding: 0 5px;
  
  width: 132em;
  margin: 0 auto;

  color: rgba(255, 255, 255, 0.70);

  p {
    font-weight: 300;
    font-size: 1.6em;
  }

  svg {
    width: 2.5em;
  }
  
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 2.5em;

    p {
      font-size: 1.2em;
    }
  }
}


.footer__right {
  display: flex;
  align-items: center;
  text-align: right;
  gap: 2.5em;

  @media (max-width: 768px) {
    gap: 1.5em;
  }
}