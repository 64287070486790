@import "../../../styles/variables.module";

.creator-cta {
  height: 40em;

  border-radius: 2.4em;
  background-color: #161C31;
  border: 0.1em solid rgba(255, 255, 255, 0.10);

  padding: 3.7em 4.7em;

  display: flex;
  justify-content: space-between;

  margin-bottom: 5em;

  @media (max-width: 768px) {
    padding: 2em 2.75em 2.75em 2.75em;
    height: unset;
  }
}

.creator-cta__left {
  width: 57em;
}

.creator-cta__right {
  flex: 1;

  display: flex;
  justify-content: end;
  align-items: center;

  svg {
    height: 75%;
  }
}

.creator-cta__title {
  margin-bottom: 2.6em;

  h1 {
    font-size: 6em;
  }

  @media (max-width: 768px) {
    margin-bottom: 2em;

    h1 {
      font-size: 4.5em;
      line-height: 120%;
    }
  }
}

.creator-cta__desc {
  margin-bottom: 4em;

  p {
    font-size: 2em;
    color: rgba(255, 255, 255, 0.70);
    font-weight: 300;
    line-height: 155%;
  }

  @media (max-width: 768px) {
    margin-bottom: 3em;

    p {
      font-size: 1.6em;
    }
  }
}

.creator-cta__button {
  background: $pink-gradient;
  width: fit-content;
  height: 5.6em;
  padding: 0 2.7em;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10em;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    height: 4.5em;
    padding: 0 2.4em;

    p {
      font-size: 1.5em;
    }
  }
}