.pink-scroll {
  .simplebar-track {
    pointer-events: auto; /* Enable pointer events on the thumb */
    cursor: pointer;      /* Change the cursor to pointer */
  }
  
  /* Hide the vertical scrollbar track */
  .simplebar-track.simplebar-vertical {
    display: none;
  }
  
  /* Customize the horizontal track */
  .simplebar-track.simplebar-horizontal {
    position: absolute;
    height: 0.6em;
    border-radius: 0.8em;
    background-color: #161C31;
  }
  
  /* Customize the horizontal thumb */
  .simplebar-scrollbar:before {
    height: 100%;
    // border-radius: 0.8em;
    top: 0;
    opacity: 1;
    background-color: #FF76E9;
  }
  
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
}