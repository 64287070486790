// HomePage.module.scss
@import '../../styles/variables.module.scss';


.blogContent {
  width: 132em;
  margin: 0 auto;
  margin-top: 7em;
  margin-bottom: 10em;

  @media (max-width: 768px) {
    width: 34em;
    margin-top: 4.5em;
    margin-bottom: 5em;
  }
}


.featureImage {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 35px;
}

/* Blog Content Styling */
.content {
  line-height: 1.6;
  color: #c6c6c6;
}

.content strong {
  font-weight: 600;
}

/* Handle headings */
.content h1, .content h2, .content h3 {
  color: #fff;
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.content h2 {
  font-size: 3em;

  @media (max-width: 768px) {
    font-size: 2.4em;
  }
}

.content h3 {
  font-size: 2.4em;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
}

/* Paragraphs */
.content p {
  margin-bottom: 1rem;
  font-size: 2em;
  font-weight: 300;
  line-height: 190%;

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
}

/* Images */
.content img {
  max-width: 100%;
  height: auto;
  border-radius: 1.8em;
  margin: 2.5em 0;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
}

/* Blockquotes */
.content blockquote {
  border-left: 4px solid #007bff;
  padding-left: 1rem;
  font-style: italic;
  color: #555;
  margin: 1.5rem 0;
}

/* Lists */
.content ul {
  list-style: disc;
  padding-left: 2em;
  font-weight: 300;
  line-height: 100%;
  font-size: 2em;

  ul {
    margin-top: 1em;
    font-size: 1em;
  }

  ::marker {
    font-size: 1.2em
  }

  li {
    margin-bottom: 1em;
    line-height: 150%;

  }

  @media (max-width: 768px) {
    font-size: 1.4em;
    padding-left: 2em;

    ::marker {
      font-size: 1em
    }
  }
}

.content ol {
  list-style: decimal;
  padding-left: 2em;
}

/* Links */
.content a {
  color: $pink-color;
  text-decoration: none;
  font-weight: 600;
  transition: 0.3s;
}

.content a:hover {
  color: #0056b3;
}

/* Code blocks */
.content pre {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  overflow-x: auto;
}

.content code {
  font-family: monospace;
  background-color: #eee;
  padding: 2px 4px;
  border-radius: 4px;
}
