@import '../../../styles/variables.module.scss';

.pf-section--history {
  display: flex;
  flex-direction: column;
}

.pf-section__game-selection {
  display: flex;
  gap: 2.6em;
  border-bottom: 0.2em solid #2D395C;

  margin: 0.8em 0 1.4em 0;

  @media (max-width: 768px) {
    gap: 1.5em;
  }
}

.pf-section__game-selection-button {
  cursor: pointer;

  padding-bottom: 1.2em;
  p {
    font-size: 1.8em;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.5em;
    }
  }

  margin-bottom: -0.2em;
}

.pf-section__game-selection-button.selected {
  border-bottom: 0.4em solid #FD9EFB;
}

.pf-section__table {
  height: 55.5em;
}

.tz-pf-table {
  tr {
    background: #20263B;
  }

  tr > th:first-child,
  tr > td:first-child {
    padding-left: 1.8em;
  }

  tr > th:last-child,
  tr > td:last-child {
    padding-left: 1.8em;
  }
}


.pf-section--history__plinko-result {
  background-color: #161C31;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  border-radius: 2.6em;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.5em 1.5em;

  p {
    margin-top: -0.1em;
    font-size: 1.2em !important;
  }
}

.pf-section--history__verify {
  background: $pink-gradient;
  border-radius: 0.4em;
  // box-shadow: 0px 0.828px 1.656px 0px rgba(255, 255, 255, 0.25) inset, 0px -1px 1px 0px rgba(0, 0, 100, 0.25) inset, 0px 15px 20px -5px rgba(0, 0, 0, 0.10);

  padding: 0.3em 1.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.2em !important;
    font-weight: 500 !important;
  }
}

.verify-modal {
  height: 120em;
  max-height: 90vh;
  width: 100em;
  overflow-y: auto;
  background-color: #161C31;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  padding: 1.5em 2em 2em 3.4em;
  border-radius: 0.8em;

  position: relative;

  @media (max-width: 768px) {
    height: 100vh;
    max-height: 100vh;
    padding: 1.5em 2em 2em 2em;
  }
}

.verify-modal__close {
  position: absolute;
  top: 2em;
  right: 2em;

  width: 2em;
  height: 2em;

  z-index: 1;

  @media (max-width: 768px) {
    top: 2.5em;
    right: 2em;
  }
}