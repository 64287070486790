.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 3em;
  height: 30em;
  width: 30em;
  font-size: 2em;
  background-color: #161C31;
}