.creator-overview {
  width: 132em;

  margin: 6em auto 7em auto;

  @media (max-width: 768px) {
    width: 34em;
    margin-top: 2em;
    margin-bottom: 5em;
  }
}

.creator-overview__title {
  h1 {
    font-size: 5.2em;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4em;
    }
  }
}

.creator-overview__header {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-top: 1.5em;
    flex-direction: column;
    gap: 1.5em;
  }
}

.creator-overview__table-toggles {
  display: flex;
  align-items: center;
  gap: 0.2em;

  height: 5.6em;
  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);

  background: #161C31;

  padding: 0.5em 0.6em;

  width: fit-content;

  @media (max-width: 768px) {
    height: 5em;

    padding: 0.4em 0.5em;
  }
}

.creator-overview__table-toggle {
  padding: 0 3em;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  
  border: 0.1em solid rgba(255, 255, 255, 0);

  p {
    font-size: 2em;
    font-weight: 400;
  }

  &.active {
    border-radius: 25em;
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background: #2A3045;
  }

  @media (max-width: 768px) {
    padding: 0 1.5em;

    p {
      font-size: 1.8em;
    }
  }
}

.creator-overview__search {
  position: relative;
  display: inline-block;

  svg {
    width: 1.8em;
    height: 1.8em;

    position: absolute;
    left: 2.2em; // Adjust as needed
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; // So clicks go through to the inpu
  }

  input {
    width: calc(37.8em / 1.7);
    height: calc(5.6em / 1.7);
    font-size: 1.7em;
    padding: 0 calc(2.6em / 1.7) 0 calc(5.2em / 1.7);
    background: #1C2339;
    border: calc(0.1em / 1.7) solid rgba(255, 255, 255, 0.20);
    border-radius: 10em;
    color: #fff;
  
    &::placeholder {
      color: rgba(255, 255, 255, 0.50);
      font-weight: 300;
    }
  
    &:active, &:focus, &:focus-visible, &:focus-within {
      border: 0.1em solid #FD9EFB;
      outline: none;
      box-shadow: none;
    }
  
    &:invalid {
      border: 0.1em solid #CC3055;
    }
  
    &:invalid + .client-seed-modal__message {
      color: #CC3055;
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    input {
      font-size: 1.4em;
      padding: 0 calc(1.8em / 1.4);
      width: 100%;
      height: calc(4.8em / 1.4);
      border: calc(0.1em / 1.4) solid rgba(255, 255, 255, 0.20);
      padding: 0 1.5em 0 3.75em;
    }
  }
}

.tz-creator-table {
  margin-top: 2.4em;

  td p {
    font-weight: 500;
  }

  @media (max-width: 768px) {
    td p, th p {
      font-size: 1.2em;
    }
  }

  .tz-table-scroll-container {
    height: 52.5em;
  }

  .creator-table__creator-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  
    gap: 0.5em;
  
    img {
      width: 1.6em;
      height: 1.6em;
      border-radius: 100%;
    }

    @media (max-width: 768px) {
      width: 14.2em;
      justify-content: start;
      overflow-x: hidden;
      gap: 0.3em;
      
      p {
        font-size: 1.1em;
      }
    }
  }
  
  .creator-table__creator-pill {
    height: 2.4em;
    padding: 0 1em 0 0.9em;
  
    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 8em;
    background: #1C2339;
    
    p {
      font-size: 1em;
    }

    @media (max-width: 768px) {
      height: 2em;
      padding: 0 0.5em 0 0.5em;

      p {
        font-size: 0.7em;
      }
    }
  }

  .creator-table__verification-cell {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .creator-table__verification-badge {
    width: 1.6em;
    height: 2em;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .creator-table__casino-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;

    img {
      width: 1.6em;
      height: 1.6em;
      border-radius: 100%;
    }
  }
  
  .creator-table__followers-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.4em;
    border-radius: 3em;
    background: #1C2339;

    gap: 0.7em;
    padding: 0 0.8em;

    img {
      width: 1.2em;
      height: 1.2em;
    }

    p {
      font-size: 1.2em; 
    }
  }

  .creator-table__view-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 2.4em;
    border-radius: 3em;
    background: #2D395C;
    padding: 0 1.2em;

    p {
      font-size: 1.2em;
    }
  }
}

