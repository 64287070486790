.tz-breakdown-table {
  tr {
    background: #20263B;
  }

  td p {
    font-size: 1.6em;
  }

  th p {
    font-size: 1.6em;
  }

  td img {}

  tr > th:first-child,
  tr > td:first-child {
    padding-left: 1.8em;
  }

  tr > th:last-child,
  tr > td:last-child {
    padding-left: 1.8em;
  }

  @media (max-width: 768px) {
    table {
      width: 90em;
    }

    tr {
      height: 3em;
    }

    td p {
      font-size: 1.3em;
    }
  
    th p {
      font-size: 1.3em;
    }
  }
}