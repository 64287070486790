@import '../../../../styles/variables.module.scss';

.platform-tools {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8em 10em 0em 10em;
  margin: 4em auto 0 auto;

  @media (max-width: 768px) {
    padding: 5em 2.5em 0 2.5em;
  }
}

.platform-tools__title {
  margin-bottom: 1.5em;
  text-align: center;

  h1 {
    font-family: "transducer-condensed", sans-serif;
    font-size: 10em;
    font-weight: 550;
  }

  @media (max-width: 768px) {
    width: 30em;
    
    span {
      display: block;
    }

    h1 {
      font-size: 5.2em;
      line-height: 120%;
    } 
  }
}

.platform-tools__desc {
  opacity: 0.7;
  margin-bottom: 4em;
  text-align: center;
  max-width: 55em;

  p {
    font-size: 2em;
  }

  @media (max-width: 768px) {
    max-width: 34em;


    p {
      font-size: 1.6em;
      font-weight: 400;
    }
  }
}

.platform-tools__options {
  display: flex;

  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17.3px);
  
  padding: 0.5em 0.6em;
  
  @media (max-width: 768px) {
    padding: 0.4em 0.5em;
  }
}

.platform-tools__option {
  border-radius: 25em;
  background-color: transparent;

  padding: 0.9em 3em 0.9em 3em;
  border: 0.1em solid rgba(255, 255, 255, 0);

  div {
    font-size: 2em;
    color: #fff;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    padding: 0.8em 2em 0.8em 2em;

    div {
      font-size: 1.6em;
    }
  }
}

.platform-tools__option.highlighted {
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #2A3045;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17.3px);
}

.platform-tools__display {
  display: flex;
  gap: 7em;

  width: 132em;
  height: 50em;
  margin-top: 6em;
  padding: 3.3em 0 0 7.4em;

  border-radius: 2.6em;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: #161C31;
  backdrop-filter: blur(17px);
  -webkit-backdrop-filter: blur(17.3px);

  overflow: hidden;

  @media (max-width: 768px) {
    gap: 3em;
    padding: 2em 0 0 2.8em;
    flex-direction: column;
    margin-top: 3em;
    width: 34em;
    height: 63em;
  }
}

.platform-tools__display__left {
  flex: 1;
  
  a {
    width: fit-content;
    display: block;
  }

  @media (max-width: 768px) {
    padding-right: 2.8em;
  }
}

.platform-tools__display__title {
  max-width: 38em;
  margin: 2.4em 0 3.7em 0;

  h2 {
    font-family: "transducer-condensed", sans-serif;
    font-weight: 550;
    font-size: 5.6em;
    line-height: 115%;
  }

  @media (max-width: 768px) {
    margin: 0 0 1.5em 0;
    max-width: 25em;

    h2 {
      font-size: 3.2em;
      line-height: 125%;
    }
  }
}

.platform-tools__display__desc {
  color: rgba(255, 255, 255, 0.70);
  font-size: 1.7em;
  font-weight: 300;
  line-height: 217%;

  @media (max-width: 768px) {
    line-height: 200%;
    font-size: 1.5em;
  }
}

.platform-tools__display__button {
  margin-top: 4.3em;
  height: 5.6em;
  width: 15.8em;
  background: $pink-gradient;
  border-radius: 10.4em;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    margin-top: 2.5em;

    height: 4.8em;
    width: 14.6em;

    p {
      font-size: 1.5em;
    }
  }
}

.platform-tools__display__right {
  width: 63.5em;
  display: flex;
  justify-content: end;

  img {
    height: 100%;
    border-top-left-radius: 2em;
  }

  @media (max-width: 768px) {
    width: 100%;

    img {
      border-top-left-radius: 1em;
    }
  }
}