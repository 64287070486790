.blog-card {
  width: 53.6em;

  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.05);
  padding: 3em 3.5em 3.5em 3.5em;

  display: flex;
  flex-direction: column;

  text-decoration: none;

  @media (max-width: 768px) {
    width: 34em;
    padding: 2.2em 2.2em 3.4em 2.2em;
  }

  &:hover {
    transition: transform 0.3s ease-in-out;
    transform: translateY(-0.5em);

    @media (max-width: 768px) {
      transition: unset;
      transform: unset;
    }
  }
}

.blog-card__img {
  width: 100%;
  border-radius: 1.8em;

  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.05);

  margin-bottom: 2.5em;

  img {
    width: 100%;
    border-radius: 1.8em;
  }
}

.blog-card__title {
  p {
    font-size: 2em;
    font-weight: 500;
  }
}

.blog-card__date {
  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 1.7em;
    font-weight: 300;
  }
}

.blog-card__footer {
  margin-top: 2.2em;

  p {
    font-size: 1.7em;
    font-weight: 300;
    line-height: 190%;
  }
}