@import '../../../../styles/variables.module.scss';

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 129em;

  @media (max-width: 768px) {
    height: 73.5em;
  }
}

.hero__background {
  position: absolute;
  overflow: hidden;

  width: 100%;
  height: calc(85em + 4px);
  top: -2px;

  z-index: -1;

  @media (max-width: 768px) {
    height: calc(57em + 4px);
  }

  
  pointer-events: none;
}

.hero__background__video {
  mix-blend-mode: luminosity;
  position: absolute;
  top: 2px;
  // left: 0;
  // transform: translateX(-50%);
  height: calc(100% - 4px);
  min-width: 100%;
  object-fit: cover; /* Ensures the video covers the container */

  pointer-events: none;
}

.hero__background__overlay {
  position: absolute;

  width: 100%;
  height: 100%;

  background: linear-gradient(180deg, rgba(17, 21, 37, 0.90) 0%, #111525 100%);
}

.hero__background__overlay2 {
  position: absolute;

  width: 100%;
  height: 50%;

  background: linear-gradient(180deg, #111525 0%, rgba(17, 21, 37, 0.00) 100%);
}

.hero__slogan {
  position: relative;

  margin-top: 5em;
  font-weight: 550;
  font-family: "transducer-condensed", sans-serif;
  text-align: center;
  z-index: 1;
  line-height: 16.4em;

  max-width: 120em;

  div {
    font-size: 16em; 
  }

  @media (max-width: 768px) {


    max-width: 35em;
    margin-top: 1em;

    div {
      font-size: 8.4em;
      line-height: 102.5%;
    }
  }
}

.hero__sub-slogan {
  margin-top: 6.4em;
  font-weight: 550;
  text-align: center;

  div {
    font-size: 2em;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.hero__contact {
  display: none;

  width: 16.4em;
  height: 5.6em;
  border-radius: 10.4em;
  background: $pink-gradient;

  align-items: center;
  justify-content: center;

  margin-top: 5em;

  div {
    font-size: 1.7em;
    font-weight: 500;
    margin-top: -0.1em;
  }

  @media (max-width: 768px) {
    display: flex;
  }
}