.pf-button-container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

}

.pf-button {
  padding: 0.8em 2.3em;

  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0.7em;

  svg {
    width: 2em;
    height: 2em;
  }

  p {
    font-size: 1.6em;
  }

  @media (max-width: 768px) {
    padding: 0.6em 1.5em;
    gap: 0.5em;

    height: 3.2em;

    svg {
      width: 1.6em;
      height: 1.6em;
    }

    p {
      font-size: 1.2em;
    }
  }
}