.tz-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tz-table-scroll-container {
  width: 100%;
  overflow-x: auto;
}

.tz-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.7em;

  tr {
    height: 4em;
    border-radius: 1.2em;
    position: relative;
    background: #161C31;
  }

  tr.tz-table-dropdown {
    height: unset;
  }

  tr.expanded .hide-on-expand {
    display: none;
  }
  
  tr.clickable:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }

  th,
  td {
    overflow: hidden;
    border-top: 0.1em solid rgba(255, 255, 255, 0.1);
    border-bottom: 0.1em solid rgba(255, 255, 255, 0.1);
    padding: 0 0.5em;

    p {
      font-size: 1.4em;
      font-weight: 400;
    }
  }

  th {
    p {
      opacity: 1;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    th,
    td {
      width: var(--mobile-width) !important;
    }
  }

  tr > th:first-child,
  tr > td:first-child {
    border-top-left-radius: 1.2em;
    border-bottom-left-radius: 1.2em;
    border-left: 0.1em solid rgba(255, 255, 255, 0.1);
    padding-left: 2.7em;
  }

  tr > th:last-child,
  tr > td:last-child {
    border-top-right-radius: 1.2em;
    border-bottom-right-radius: 1.2em;
    border-right: 0.1em solid rgba(255, 255, 255, 0.1);
    padding-right: 2.7em;
  }

  @media (max-width: 768px) {
    tr > th:first-child,
    tr > td:first-child {
      padding-left: 1.5em;
    }

    tr > th:last-child,
    tr > td:last-child {
      padding-right: 1.5em;
    }
  }
}


.tz-table-pagination {
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.tz-table-pagination__buttons {
  display: flex;
  gap: 2em;

  button {
    cursor: pointer;
    background: none;
    border: none;

    p {
      font-size: 1.4em;
      color: #fff;
    }

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}
