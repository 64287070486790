@import '../../styles/variables.module.scss';

.provably-fair {
  width: 132.2em;
  height: 75em;
  max-height: 90vh;
  border-radius: 3.2em;

  background-color: #161C31;

  padding: 3.2em 2.6em 3em 3.2em;

  display: flex;
  gap: 7em;

  position: relative;
  

  @media (max-width: 768px) {
    padding: 2.2em 2em 2em 2em;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    
    width: 39em;
    gap: 0.5em;

    flex-direction: column;

    overflow-y: auto;
  }
}

.provably-fair__left {

  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 2em;

  width: 23em;

  @media (max-width: 768px) {
    height: unset;
    width: 100%;
  }
}

.provably-fair__menu {
  display: flex;
  flex-direction: column;
  gap: 1.4em;

  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #20263B;

  padding: 2.6em 3.2em 2.6em 2.9em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2em 3.2em 2em 2.4em;
  }
}

.provably-fair__menu-item {
  display: flex;
  gap: 1.3em;
  align-items: center;
  cursor: pointer;
}

.provably-fair__menu-item__icon {
  width: 2em;
  height: 2em;

  svg {
    width: 100%;
    height: 100%;
  }
}

.provably-fair__menu-item__text {
  font-size: 1.6em;
  font-weight: 500;
}

.provably-fair__right {
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 1.6em;

  @media (max-width: 768px) {
    margin-top: 2em;
  }

}

.provably-fair__close-button {
  position: absolute;
  top: 3em;
  right: 3em;
  z-index: 3;
  background: transparent;
  border: none;
  
  width: 2em;
  height: 2em;

  cursor: pointer;

  @media (max-width: 768px) {
    top: 2.5em;
    right: 2em;
  }
}

.provably-fair__content {
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.provably-fair__key-infos {
  display: flex;
  flex-direction: column;
  gap: 1em;

  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #20263B;

  padding: 2.8em 2em 2.8em 2em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 2em 3.2em 2em 2.4em;
  }
}

.provably-fair__close {
  cursor: pointer;
  width: 2em;
  height: 2em;
}

.provably-fair__key-info {
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}

.provably-fair__key-info__label {
  display: flex;
  align-items: center;
  gap: 1em;
  p {
    font-size: 1.4em;
    font-weight: 500;
  }
}

.provably-fair__key-info__box {
  border-radius: 0.8em;
  // border: 0.2em solid #2D395C;
  background: #161C31;
  border: 0.2em solid rgba(255, 255, 255, 0.10);

  display: flex;
  align-items: center;
  gap: 0.8em;

  padding: 0.8em 1.6em;
}

.provably-fair__key-info__value {
  font-size: 1.6em;
  flex: 1;

  opacity: 0.5;

  white-space: nowrap;       /* Keep text on a single line */
  overflow: hidden;          /* Hide overflow text */
  text-overflow: ellipsis;   /* Show an ellipsis (...) for overflowed text */
}

.provably-fair__key-info__copy {
  width: 1.6em;
  height: 1.6em;

  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

.provably-fair__key-info__update {
  font-size: 1.4em;
  color: #FD9EFB;
  cursor: pointer;
}

.client-seed-modal {
  background-color: #161C31;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  padding: 2em 2.4em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  border-radius: 0.8em;
}

.client-seed-modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 2em;
    font-weight: 600;
  }

  svg {
    width: 1.6em;
    height: 1.6em;

    cursor: pointer;
  }
}

.client-seed-modal__content {
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  margin-bottom: 0.8em;

  p {
    font-size: 1.4em;
    font-weight: 400;
  }
}

.client-seed-modal__input {
  width: calc(46em / 1.6);
  height: calc(4.8em / 1.6);
  font-size: 1.6em;
  padding: calc(1.2em / 1.6) calc(1.6em / 1.6);
  background: #111525;
  border: 0.15em solid rgba(255, 255, 255, 0.10);
  border-radius: 0.8em;
  color: #fff;
  margin-left: -0.1em;

  @media (max-width: 768px) {
    width: calc(34em / 1.6);
  }

  &:active, &:focus, &:focus-visible, &:focus-within {
    border: 0.15em solid #FD9EFB;
    outline: none;
    box-shadow: none;
  }

  &:invalid {
    border: 0.15em solid #CC3055;
  }

  
  &:invalid + .client-seed-modal__message {
    color: #CC3055;
  }
}


.client-seed-modal__update {
  width: 17em;
  height: 4.6em;
  border-radius: 0.8em;
  border: none;
  background: $pink-gradient;
  cursor: pointer;

  p {
    font-size: 1.7em;
    color: #fff;
    font-weight: 600;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.pf-section {
  height: 100%;
  width: 92em;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.pf-section code {
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #20263B;
  font-size: 1.2em;
  padding: 1em;
  display: inline-block;
  white-space: pre-wrap 
}

.pf-section--main {
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.pf-section--implementation {
  display: flex;
  gap: 1em;
  flex-direction: column;
}

.pf-section--main__content {
  display: flex;
  gap: 2em;
  flex-direction: column;
}

.pf-section__title {
  h1 {
    font-family: 'transducer-condensed';
    font-weight: 550;
    font-size: 4em;
  }
}

.pf-section__sub-title {
  h2 {
    font-size: 1.8em;
  }

  margin-bottom: 2em;
}

.pf-main__paragraph {
  
}


.pf-main__desc {
  p, li {
    color: rgba(255, 255, 255, 0.60);
    font-size: 1.5em;
    font-weight: 300;
  }
}







// scroll
.provably-fair__scroll {
  .simplebar-track {
    pointer-events: auto;
    cursor: pointer;   

    @media (max-width: 768px) {
      display: none;
    }
  }
  
  .simplebar-track.simplebar-horizontal {
    display: none;
  }
  
  .simplebar-track.simplebar-vertical {
    position: absolute;
    top: 4em;
    right: -1.5em;
    height: calc(100% - 4em);
    width: 0.8em;
    border-radius: 0.8em;
    background-color: #20263B;
  }
  
  /* Customize the horizontal thumb */
  .simplebar-scrollbar:before {
    width: 100%;
    border-radius: 0.8em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    background-color: #4D5162;
  }
  
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }
}