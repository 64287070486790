@import '../../styles/variables.module.scss';

.blog-cta__desc {
  margin-bottom: 2em;

  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 2em;
    font-weight: 300;
    line-height: 100%;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.6em;
      max-width: 15em;
      line-height: 185%;
    }
  }
}

.blog-cta__subscribe {
  display: flex;
  gap: 0.8em;
}

.blog-cta__subscribe input{
  width: calc(37.8em / 1.6);
  height: calc(5.6em / 1.6);
  font-size: 1.6em;
  padding: 0 calc(2.6em / 1.6);
  background: #171B2A;
  border: calc(0.1em / 1.6) solid rgba(255, 255, 255, 0.20);
  border-radius: 10em;
  color: #fff;


  &::placeholder {
    color: rgba(255, 255, 255, 0.50);
    font-weight: 300;
  }

  &:active, &:focus, &:focus-visible, &:focus-within {
    border: 0.1em solid #FD9EFB;
    outline: none;
    box-shadow: none;
  }

  &:invalid {
    border: 0.1em solid #CC3055;
  }

  &:invalid + .client-seed-modal__message {
    color: #CC3055;
  }

  @media (max-width: 768px) {
    font-size: 1.4em;
    padding: 0 calc(1.8em / 1.4);
    width: calc(19.3em / 1.4);
    height: calc(4.8em / 1.4);
    border: calc(0.1em / 1.4) solid rgba(255, 255, 255, 0.20);
  }
}

.blog-cta__subscribe-btn {
  background: $pink-gradient;
  width: 16em;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10em;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    width: 14em;

    p {
      font-size: 1.5em;
    }
  }
}

.blog-cta__response, .blog-cta__error {
  p {
    margin-top: 1.4em;
    font-size: 1.8em;
    font-weight: 400;
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    p {
      margin-top: 1.4em;
      font-size: 1.4em;
    }
  }
}






// .blog-cta__desc {
//   margin-bottom: 2em;

//   p {
//     color: rgba(255, 255, 255, 0.70);
//     font-size: 2em;
//     font-weight: 300;
//     line-height: 185%;
//   }

//   @media (max-width: 768px) {
//     p {
//       font-size: 1.6em;
//       max-width: 15em;
//     }
//   }
// }

// .blog-cta__subscribe {
//   width: 50em;
//   height: 5.5em;
//   position: relative;
//   overflow: hidden;
//   border-radius: 1em;

//   iframe {
//     width: 100%;
//     height: 100%;
//   }

//   @media (max-width: 768px) {
//     width: 34em;
//   }
// }