.error-message {
  width: 100%;
  height: 100%;
  min-height: 20em;
  margin: 5em 0;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
    font-size: 2em;
    color: #CC3055;
  }
}