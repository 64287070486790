.creator-stream {
  border-radius: 1.6em;
  background: #1E2741;
}

.creator-stream__header {
  height: 9.8em;
  padding: 0 2.5em 0 2.6em;

  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

  @media (max-width: 768px) {
    height: 8em;
    padding: 0 2em 0 2em;
  }
}

.creator-stream__header__left {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  flex: 1;
  gap: 0.25em;
}

.creator-stream__title {
  display: flex;
  gap: 1em;

  p {
    font-size: 2em;
    text-wrap: nowrap;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.7em;
    }
  }
}

.creator-stream__date {
  p {
    font-size: 1.7em;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 300;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.4em;
    }
  }
}

.creator-stream__live {
  display: flex;
  align-items: center;
  gap: 1em;
}

.creator-stream__live-icon {
  width: 1.4em;
  height: 1.4em;
  background-color: #F1271D;
  border-radius: 100%;

  @media (max-width: 768px) {
    width: 1.2em;
    height: 1.2em;
  }
}

.creator-stream__content {
  border-top: 0.1em solid #2D395C;
  padding: 2.5em 2.5em 3em 2.6em;


  @media (max-width: 768px) {
    padding: 2em 2em 2.5em 2em;
  }
}