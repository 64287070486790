.container {
  position: relative;
  display: inline-block;
  width: 24.5em;

  @media (max-width: 768px) {
    width: 21.5em;
  }
}

.selectButton {
  display: flex;
  align-items: center;
  justify-content: space-between; /* label on left, arrow (and icons) on right */
  width: 100%;
  height: 4.8em;
  padding: 0 2.3em;
  border-radius: 10em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #171B2A;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 4em;
  }
}

/* Label on the left side */
.selectLabel {
  flex: 1; /* takes remaining horizontal space */
  text-align: left;
  font-size: 1.7em;

  color: #fff;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 1.4em;
  }
}

.rightSide {
  display: flex;
  align-items: center;

  margin-top: 0.1em;
  width: calc(1.2em * 1.2);
  height: calc(0.74em * 1.2);

  svg {
    width: 100%;
    height: 100%;
  }
}

.dropdown {
  padding: 0.4em 0.4em;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  right: 0;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #171B2A;
  border-radius: 1.5em;
  margin-top: 1em;
  max-height: 75em;
  overflow-y: auto;
  z-index: 999;
}

.option {
  display: flex;
  align-items: center;
  padding: 0.8em 1.2em;
  cursor: pointer;
}


/* The label can flex: 1 so it uses remaining space, 
   pushing the right icons to the far right */
.optionLabel {
  flex: 1;

  p {
    font-weight: 300 !important;
  }
}

/* For the container that holds the right icons */
.rightIconsContainer {
  display: flex;  /* so multiple icons sit horizontally */
  align-items: center;
  margin-left: 8px; /* some spacing between label and icons */
}

.leftIcon {
  margin-right: 1em;

  img {
    border-radius: 0.5em;
    width: 2em;
    height: 2em;
  }
}

.rightIcon {
  margin-left: 0.4em;
  filter: brightness(70%) invert(1) grayscale(100%);

  img {
    width: 1.6em;
    height: 1.6em;
  }
}

.selected {
  color: #FD9EFB;
}
