@import '../../styles/variables.module.scss';

.audits {
  width: 132em;
  margin: 0 auto;
  margin-bottom: 10em;

  @media (max-width: 768px) {
    width: 34em;

    margin-bottom: 5em;
  }
}

.score-button-container {
  width: 100%;
  height: 3.5em;
  display: flex;
  justify-content: end;
}

.score-button {
  background: $pink-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2em;
  padding: 0 2em;

  p {
    font-size: 1.6em
  }
}

.audits-table {
  margin-top: 2.8em;

  @media (max-width: 768px) {
    margin-top: 3em;
  }
}

.audits-table__casino {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6em;

  img {
    margin-top: 0.1em;
    width: 1.8em;
    height: 1.8em;
    border-radius: 0.4em;
  }
}

.audits-table__score {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #1C2339;
  border-radius: 0.6em;
  padding: 0 0.8em;

  p {
    font-size: 1.2em;
    line-height: 180%;
    font-weight: 400 !important;
  }
}

.tz-audits-table tr.expanded {
  background: #1C2339;
}

.tz-audits-table tr.expanded .audits-table__arrow {
  transform: rotate(180deg);
}

.audits-table__arrow {
  width: 1.2em;
  height: 0.74em;
}

.audits-table__dropdown {
  padding: 1.2em 0 1.4em 0;

  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.audits-table__category:nth-child(1) {
  flex: 1;
}

.audits-table__category-divider {
  width: 0.1em;
  background: rgba(255, 255, 255, 0.10);
  margin: 0 2em;

  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
    margin: 2em 0;
  }
}

.audits-table__category__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

.audits-table__category__title {
  margin-right: 2.8em;

  p {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 170%;
  }
}

.audits-table__metric {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tz-table .audits-table__metric__title {
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.2em;
    font-weight: 300;
    line-height: 200%;
  }
}

.tz-table .audits-table__metric__value {
  width: 8.5em;
  margin-left: 2em;

  p {
    text-align: right;
    font-size: 1.2em;

    a {
      color: #FD9EFB;
    }
  }
}