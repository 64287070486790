.creator-page {
  margin: 0 auto;
  width: 132em;
  margin-top: 2em;

  @media (max-width: 768px) {
    width: 34em;
  }
}

.creator-data {
  display: flex;
  gap: 2.4em;
  margin-bottom: 5em;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.creator-data__left {
  width: 31.2em;
  top: 14em;              
  align-self: flex-start;

  display: flex;
  flex-direction: column;
  gap: 2.4em;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.creator-data__right {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 2.4em;
}

.creator-data__card {
  border-radius: 2.6em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;
}

.creator-data__card--profile {
  width: 100%;
}


// Profile
.creator-data__card--profile__pfp {
  margin: 4em auto 2em auto;
  width: fit-content;
  position: relative;

  img {
    width: 13.2em;
    height: 13.2em;
    border-radius: 100%;
  }
}

.creator-data__card--profile__pfp__badge {
  position: absolute;
  top: 0;
  right: 0;

  width: 2.7em;
  height: 3.4em;
}

.creator-data__card--profile__name {
  p {
    font-size: 3em;
    font-weight: 500;
    text-align: center;
  }
}

.creator-data__card--profile__socials {
  margin-top: 2em;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 4.5em;
}

.creator-data__card--profile__social {
  img {
    width: 2em;
    height: 2em;
  }
}


// About
.creator-data__about {
  padding: 2.5em 4em 3.5em 4em;

  @media (max-width: 768px) {
    padding: 1.5em 2em 2.5em 2em;
  }
}

.creator-data__about__bio {
  p {
    font-size: 1.7em;
    line-height: 190%;
    color: rgba(255, 255, 255, 0.70);
  }

  margin-bottom: 3em;

  @media (max-width: 768px) 
  {
    margin-bottom: 2em;

    p {
      font-size: 1.4em;
    }
  }
}

.creator-data__about__bio__title {
  margin-bottom: 1.4em;

  h1 {
    font-size: 4em;
  }

  @media (max-width: 768px) 
  {
    margin-bottom: 1em;

    h1 {
      font-size: 3.5em;
    }
  }
}

.creator-data__about__socials {
  h1 {
    font-size: 3.2em;
  }
}

.creator-data__about__socials-list {
  margin-top: 2.5em;
  display: flex;
  gap: 1.6em;
  overflow: auto;

  @media (max-width: 768px) 
  {
    margin-top: 1.5em;
  }
}

.creator-data__about__social {
  width: 12em;
  height: 12em;
  border-radius: 1.6em;
  background: #1E2741;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.6em;

  img {
    width: 2.6em;
    height: 2.6em;
  }

  p {
    font-size: 1.6em;
    font-weight: 500;
    color: #fff;
  }
}


// Levels
.creator-data__card--level {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.25em 0 1em 0;
}

.creator-level {
  display: flex;
  gap: 2.7em;

  padding: 1.3em 1.8em 1.8em 2.4em;
  opacity: 0.5;

  &.highlighted {
    opacity: 1;
    background-color: #1E2741;
  }
}

.creator-level__left {
  display: flex;
  gap: 1em;
  align-items: start;

  p {
    font-size: 1.8em;
    font-weight: 500;
  }
}

.creator-level__badge {
  width: 2.1em;
  height: 2.6em
}

.creator-level__right {
  p {
    font-size: 1.5em;
    color: rgba(255, 255, 255, 0.70);
  }
}



// Deals
.creator-data__card--current-deals, .creator-data__card--previous-deals, .creator-data__card--streams {
  padding: 2.25em 3.8em 4.6em 4em;

  @media (max-width: 768px) {
    padding: 1.5em 2em 2.5em 2em;
  }
}

.creator-deals__header {
  margin-bottom: 3em;

  h1 {
    font-size: 4em;
    line-height: 150%;
  }

  p {
    font-size: 1.7em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.70);
  }

  @media (max-width: 768px) {
    margin-bottom: 2em;

    h1 {
      font-size: 3.5em;
    }

    p {
      font-size: 1.4em;
    }
  }
}

.creator-deals__list {
  display: flex;
  flex-direction: column;
  gap: 2.4em;

  @media (max-width: 768px) {
    gap: 1.6em;
  }
}

.creator-deals__no-data {
  padding: 1.5em 0 1.5em 3em;
  background-color: #1E2741;
  border-radius: 1em;

  p {
    font-size: 1.8em;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.4em;
    }
  }
}