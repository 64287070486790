.dashboard__last-updated {
  height: 3em;
  display: flex;
  justify-content: end;
  align-items: center;

  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 1.4em;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2em;
    }
  }
}

.toolbar {
  width: 100%;
  height: 8em;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    height: unset;
    flex-direction: column;

    gap: 1em;
  }
}

.toolbar__left {
  display: flex;
  gap: 3em;

  @media (max-width: 768px) {
    gap: 1em;
    flex-direction: column;
  }
}

.toolbar__variable {
  display: flex;
  gap: 2em;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    gap: 2.5em;
    margin-right: -0.4em;
    p {
      font-size: 1.4em;
    }
  }
}

.toolbar__timeframes {
  display: flex;
  gap: 1.6em;
  margin-right: -0.8em;

  @media (max-width: 768px) {
    margin: 0;
    gap: 1em;
  }
}

.toolbar__timeframe-button {
  border-radius: 10em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #171B2A;
  height: 4.8em;
  display: flex;
  align-items: center;
  padding: 0 2.6em;

  p {
    font-size: 1.7em;
    font-weight: 500;
  }

  &.active {
    background-color: #2D395C;
  }

  @media (max-width: 768px) {
    height: 4em;
    padding: 0 2.4em;

    p {
      font-size: 1.4em;
      font-weight: 500;
    }
  }
}