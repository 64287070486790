@import '../../../styles/variables.module.scss';

.pf-section__title--verify {

}

.pf-section__sub-title--verify {
  margin-top: 1em;
  margin-bottom: 1em;
  
  h2 {
    font-weight: 600;
  }
}

.pf-section--verify__fields {
  margin-top: 2em;

  display: flex;
  flex-direction: column;
  gap: 1.2em;

  
  @media (max-width: 768px) {
    margin-top: 1em;
    gap: 0.1em;
  }
}

.pf-section--verify__field {
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    margin-left: 0.2em;
    flex-direction: column;
    gap: 0.5em;
  }

  label {
    min-width: 14em;

    p {
      margin-top: 0.6em;
      font-size: 1.6em;
      font-weight: 500;
    }
  }

  input, select {
    
    width: 100%;
    height: calc(4.8em / 1.6);
    font-size: 1.6em;
    padding: calc(1.2em / 1.6) calc(1.6em / 1.6);
    background: #111525;
    border: 0.15em solid rgba(255, 255, 255, 0.10);
    border-radius: 0.8em;
    color: #fff;
    margin-left: -0.1em;
  
    &:active, &:focus, &:focus-visible, &:focus-within {
      border: 0.15em solid #FD9EFB;
      outline: none;
      box-shadow: none;
    }
  
    &:invalid {
      border: 0.15em solid #CC3055;
    }
  
    
    &:invalid + .client-seed-modal__message {
      color: #CC3055;
    }
  }

  select {
    padding: 0 calc(1.6em / 1.6);
  }
}

.pf-section--verify__field__select {
  width: 100%;
  position: relative;
  display: inline-block;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    width: 100%;

  }

  .pf-section--verify__field__select-arrow {
    pointer-events: none;      /* So clicks go to the select */
    position: absolute;
    top: 53%;
    right: 1em;              /* Adjust to control right padding */
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
  }
}

.pf-section--verify__field__input-group {
  width: 100%;
}

/* Style for invalid input: change the border color to red */
.pf-section--verify__field__input-group input:invalid {
  border-color: red;
}

/* Error message default state: hidden */
.pf-section--verify__field__input-group .error {
  display: none;
  color: red;
  font-size: 0.7rem;
  margin-top: 0.1em;
}

/* When the input is invalid, display the error message */
.pf-section--verify__field__input-group input:invalid + .error {
  display: block;
}

.pf-section--verify__message {
  display: flex;
  align-items: center;
  gap: 1em;

  svg {
    width: 2em;
  }
  
  p {
    font-size: 1.6em;
  }

  @media (max-width: 768px) {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.pf-section--verify__vrf--warning {
  color: #f0e850;
}

.pf-section--verify__vrf--unverified {
  color: #F05076;
}

.pf-section--verify__vrf--verified {
  color: #3CB765;
}

.pf-section--verify__game-result {
  width: 100%;
  padding: 2em 1em;
  background: #111525;
  border: 0.3em dotted rgba(255, 255, 255, 0.10);
  border-radius: 0.8em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pf-section--verify__plinko-card {
  .card {
    width: 40em;
  }

  @media (max-width: 768px) {
    .card {
      width: 20em;
    }
  }
}

.pf-calculation__table-container {
  width: 73em;
  overflow-x: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.pf-calculation {
  margin-top: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;

  .bold {
    font-weight: 800;
  }

  table {
    width: 100%;
    table-layout: fixed;

    text-align: center;
    border-collapse: separate;

    td {
      padding-left: 0.6em;
      font-size: 1.6em;
      font-family: monospace;
    }

    td:first-child {
      padding-left: 0;
    }
  }
}

.pf-calculation__section {
  width: 100%;
}

.pf-calculation__header {
  margin-bottom: 0.5em;

  p {
    color: #FD9EFB;
    font-size: 1.6em;
  }
}

.pf-calculation__hash {
  p {
    font-family: monospace;
    font-size: 1.6em;
  }
}

.pf-calculation__to-numbers {
  display: flex;
  gap: 5em;
}

.pf-calculation__to-number {
  width: 50em;

  p {
    font-family: monospace;
    font-size: 1.6em;
  }
}

.pf-calculation__final-result {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  p {
    font-size: 1.6em;
    font-family: monospace;
  }
}

.pf-calculation__final-result__options {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  p {
    text-align: center;
  }
}

.pf-calculation__final-result__option {
  padding: 0.4em 1em;

  &.highlight {
    color: #3CB765;
    font-weight: 800;
  }

  @media (max-width: 768px) {
    padding: 0.4em 0.1em;
    width: 13em;
  }
}