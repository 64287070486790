@keyframes bounce-transform {
  0% {
    transform: translateY(0);
    // border: 5px solid red;
  }
  50% {
    transform: translateY(1.5em);
    @media (max-width: 768px) {
      transform: translateY(1em);
    }
  }
  100% {
    transform: translateY(0);
    border-color: default;
  }
}

.plinko {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120em;
  z-index: 0;

  @media (max-width: 768px) {
      width: 35em;
  }
}

.plinko__controls {
  margin-top: 4em;
  display: flex;
  gap: 1em;

  @media (max-width: 768px) {
    margin-top: 2em;
  }
}

.plinko__play {

  border-radius: 25em;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  background: #161C31;

  display: flex;
  align-items: center;
  justify-content: center;


  gap: 0.7em;

  .plinko__icon {
    
  display: flex;
  align-items: center;
  justify-content: center;

    width: 2em;
    height: 2em;
  }

  width: 15em;
  height: 4em;

  p {
    font-size: 1.6em;
  }

  @media (max-width: 768px) {
    padding: 0.6em 1.5em;
    gap: 0.5em;

    width: 8.5em;
    height: 3.2em;

    svg {
      width: 1.6em;
      height: 1.6em;
    }

    p {
      font-size: 1.2em;
    }
  }
}

.plinko canvas {
  margin-top: -135em;

  width: 152em;
  opacity: 1;

  @media (max-width: 768px) {
      margin-top: 0;
      width: 35em;
    }
}

.cards {
  display: flex;
  justify-content: center;
  gap: 2.7em;

  @media (max-width: 768px) {
    border-radius: 1.6em;
    gap: 0.7em;
  }
}

.card {
  width: 29%;
  background-color: #161C31;
  border: 0.1em solid rgba(255, 255, 255, 0.10);
  border-radius: 2.6em;
  padding: 1.7em 3.7em 2.3em 3.7em;
  display: flex;
  gap: 1.5em;
  flex-direction: column;
  transition: box-shadow 0s, border-color 0.3s;

  @media (max-width: 768px) {
    border-radius: 1.6em;
    width: 10.5em;
    height: 3.2em;
    justify-content: center;
    padding: 0;
    align-items: center;
  }
}

.card__title {
  font-weight: 500;
  font-size: 2em;

  @media (max-width: 768px) {
    font-size: 1em;
  }
}

.card__desc {
  font-weight: 300;
  font-size: 1.6em;

  @media (max-width: 768px) {
    display: none;
  }
}

.card.bounce {
  animation: 
    bounce-transform 0.2s ease,
    instant-box-shadow 1s steps(1, end) forwards;
}

.plinko__pf-button {
  position: absolute;
  bottom: 0;
  margin-top: 10em;
}