.blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6em;
  margin-bottom: 4em;
  padding-bottom: 4em;


  @media (max-width: 768px) {
    margin-top: 4em;
    margin-bottom: 0em;
  }
}

.blog__content {
  width: 132em;
  
  @media (max-width: 768px) {
    width: 34em;
  }
}

.blog__title {
  color: #FFF;

  margin-bottom: 1em;

  h1 {
    font-size: 10em;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 5.2em;
    }

    margin-bottom: 0.5em;
  }
}

.blog__cards-container {
  width: 100%;
  margin-top: 5em;
  height: 47.5em;

  @media (max-width: 768px) {
    width: 34em;
    margin-top: 2.5em;
  }
}


.blog__cards {
  margin-top: 0.6em;
  display: flex;
  flex-wrap: nowrap;      /* prevent wrapping so items overflow horizontally */
  gap: 2.4em;              /* optional: adds spacing between the cards */



  @media (max-width: 768px) {
    gap: 0.8em;    
  }
}