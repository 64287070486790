.supporters {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5em 0 8em 0;

  @media (max-width: 768px) {
    padding: 4em 0 5em 0;
  }
}
.supporters__title {
  h1 {
    font-size: 8em;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 5.2em;
    }
  }
}

.supporters__desc {
  text-align: center;

  width: 56em;

  p {
    color: rgba(255, 255, 255, 0.70);
    font-size: 1.6em;
    line-height: 230%;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    width: 34em;

    p {
      font-size: 1.6em;
      line-height: 200%;
    }
  }
}

.supporters__list {
  margin-top: 3.5em;
  display: flex;
  gap: 2.5em;

  @media (max-width: 768px) {
    margin-top: 2em;
    gap: 1.5em;
  }
}

.supporters__item {
  width: 18.22em;
  height: 7.26em;

  @media (max-width: 768px) {
    width: calc(18.22em / 1.5);
    height: calc(7.26 / 1.5);
  }
}

