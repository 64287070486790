@import '../../styles/variables.module.scss';

.navbar {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;

  width: 132em;
  padding: 3.2em 0;
  z-index: 3;

  @media (max-width: 768px) {
    padding: 1.5em 2.5em;
    width: 100%;
    overflow: hidden;
  }

  pointer-events: none;

  a,
  .navbar__dropdown-button {
    pointer-events: auto;
  }

  /* ==================================== */
  /* LOGO */
  /* ==================================== */
  &__logo {
    width: 5.4em;
    height: 4.1em;

    svg {
      margin-left: 0%;
      margin-top: 15%;

      width: 100%;
      height: 100%;
    }

    @media (max-width: 768px) {
      display: flex; /* Shown on mobile */
      z-index: 11;
    }
  }

  /* ==================================== */
  /* DESKTOP LINKS */
  /* ==================================== */
  &__links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 50.3em;
    height: 5.6em;
    border-radius: 25em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3.2em;

    margin: 0 auto;

    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17.3px);

    a p {
      text-decoration: none;
      font-size: 2em;
      color: #fff;
    }

    @media (max-width: 768px) {
      display: none; /* Hide the desktop links on mobile */
    }
  }

  /* ==================================== */
  /* DESKTOP "GET IN TOUCH" BUTTON */
  /* ==================================== */
  &__contact {
    width: 16.4em;
    height: 5.6em;
    border-radius: 10.4em;
    background: $pink-gradient;

    display: flex;
    align-items: center;
    justify-content: center;

    div {
      font-size: 1.7em;
      font-weight: 500;
      margin-top: -0.1em;
    }

    @media (max-width: 768px) {
      display: none; /* Hide on mobile; it will appear inside dropdown menu */
    }
  }

  /* ==================================== */
  /* MOBILE MENU TOGGLE (BURGER/CLOSE) */
  /* ==================================== */
  &__dropdown-button {
    display: none; /* Hidden on desktop */

    justify-content: center;
    align-items: center;

    width: 7.2em;
    height: 4.8em;

    border-radius: 25em;
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17.3px);

    svg {
      width: 2.4em;
      height: 2.4em;
    }

    @media (max-width: 768px) {
      display: flex; /* Shown on mobile */
      z-index: 11;
    }
  }

  /* ==================================== */
  /* MOBILE OVERLAY (click outside to close) */
  /* ==================================== */
  &__overlay {
    /* Covers entire screen behind the menu */
    position: fixed;
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0;
    background: rgba(17, 21, 37, 0.60);
    backdrop-filter: blur(17px);
    -webkit-backdrop-filter: blur(17.3px);
    z-index: 9; 
    pointer-events: auto;

    @media (min-width: 768px) {
      display: none; /* No overlay on desktop */
    }
  }

  /* ==================================== */
  /* MOBILE DROPDOWN MENU */
  /* ==================================== */
  &__dropdown-menu {
    /* Positioned on top of everything else */
    position: fixed;
    top: 0.6em;
    left: 0.6em;
    right: 0.6em;

    padding: 1em 1.9em 3em 1.9em;
    border-radius: 0.8em;
    border: 0.1em solid rgba(255, 255, 255, 0.10);
    background: #161C31;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5em;

    z-index: 10; 
    pointer-events: auto;

    @media (min-width: 768px) {
      display: none; /* Hide dropdown on desktop */
    }

    &__title {
      width: 100%;
      display: flex;
      gap: 1em;
      align-items: center;

      h1 {
        font-size: 3em;
      }

      svg {
        width: 3.5em;
        height: 3.5em;
      }
    }

    /* Style the links inside the mobile menu */
    &__links {
      margin-top: 6.5em;

      .navbar__link--mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
          width: 2.4em;
          height: 2.4em;
        }
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1em;
      width: 100%;

      a {
        width: 100%;
        text-decoration: none;
        padding: 1em 0;
        
        p {
          font-size: 2em;
          color: #fff;
        }
      }
    }

    &__seperator {
      height: 0.1em;
      width: 100%;
      background: rgba(255, 255, 255, 0.10);
    }

    /* Reuse or re-style the contact button in the mobile menu */
    .navbar__contact {
      display: flex;
      width: 34.4em;
      height: 5.6em;
      border-radius: 10.4em;
      background: $pink-gradient;
      align-items: center;
      justify-content: center;

      div {
        font-size: 1.7em;
        font-weight: 500;
        margin-top: -0.1em;
      }
    }
  }
}

/* ==================================== */
/* BASE STYLE FOR ALL NAVBAR LINKS */
/* ==================================== */
.navbar__link {
  color: white;
  transition: opacity 0.3s ease;
}

/* === State: When one link is active (has-active) === */
.navbar__links.has-active .navbar__link {
  opacity: 0.6;
}
.navbar__links.has-active .navbar__link.active {
  opacity: 1;
}
/* Hover overrides */
.navbar__links.has-active:hover .navbar__link {
  opacity: 0.6;
}
.navbar__links.has-active:hover .navbar__link:hover {
  opacity: 1;
}

/* === State: When no link is active (none-active) === */
.navbar__links.none-active .navbar__link {
  opacity: 1;
}
.navbar__links.none-active:hover .navbar__link {
  opacity: 0.6;
}
.navbar__links.none-active .navbar__link:hover {
  opacity: 1;
}
