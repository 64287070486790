.header__title {
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 2em;

  h1 {
    font-size: 10em;
    text-align: center;
    line-height: 100%;
  }

  @media (max-width: 768px) {
    width: 34em;
    
    h1 {
      font-size: 6.8em;
    }
  }
}

.header__desc {
  margin: 0 auto;

  p {
    font-size: 2em;
    font-weight: 300;
    text-align: center;
    color: rgba(255, 255, 255, 0.70);
  }

  @media (max-width: 768px) {
    width: 34em;

    p {
      font-size: 1.6em;
    }
  }
}