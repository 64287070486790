.stream-chart {
  position: relative;
  width: 100%;
 
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media (max-width: 768px) {
    gap: 1.5em;
  }
}

.stream-chart__chart-container {
  position: relative;
  height: 60em;

  @media (max-width: 768px) {
    height: 18.5em;
  }
}

.stream-chart__watermark {
  position: absolute;
}

.stream-chart__analytics-items {
  display: flex;
  width: 100%;
  gap: 1em;
  flex-wrap: wrap;
}

.stream-chart__analytics-item {
  padding: 1.6em 2em;
  border-radius: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  background: rgb(26, 33, 54);

  text-align: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 1.2em 2em;
    width: 100%;
  }
}

.stream-chart__analytics-title {
  p {
    font-size: 1.4em;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2em;
    }
  }
}

.stream-chart__analytics-number {
  p {
    font-size: 1.4em;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    p {
      font-size: 1.2em;
    }
  }
}