.creators-section {
  margin: 0 auto;
  width: 132em;
  margin-top: 5em;

  @media (max-width: 768px) {
    width: 34em;
  }
}

.creator-badge {
  width: 100%;
  height: 100%;
  margin-top: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}