.blogs-list {
  width: 132em;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  column-gap: 2%;
  row-gap: 2.4em;

  margin-bottom: 10em;

  a {
    width: 32%;

    .blog-card {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    width: 34em;
    margin-bottom: 5em;

    a {
      width: 100%;
    }
  }
}

.blog-page__cta {
  margin: 0 auto;

  display: flex;
  justify-content: center;
  text-align: center;

  margin-top: 3em;
  margin-bottom: 7em;

  .blog-cta .blog-cta__desc {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5em;
  }

  @media (max-width: 768px) {
    margin-top: 2em;
    margin-bottom: 4.5em;

    .blog-cta .blog-cta__desc {
      margin-bottom: 2.5em;
    }
  }
}